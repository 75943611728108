import React, { Component } from "react"
// import { Link, navigate } from "gatsby"
import { Row, Col } from "react-bootstrap"
// import addaplay2 from "../../../img/newnbest/addaplay2.jpg"
// import addapantone from "../../../img/newnbest/addapantone.jpg"
// import addapantone2 from "../../../img/newnbest/addapantone2.jpg"
// import addaavenger from "../../../img/newnbest/addaavenger.jpg"
// import A4 from "../../../img/newnbest/A4.jpg"ฝ
import Axios from "axios"
import { REQUEST_URL, PATH_URL } from "../../../config"

class PreviewNew extends Component {
  state = {
    data: [],
    Page: 5,
    Active: 1,
    PageCount: [],
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.Active !== this.state.Active) {
      let offset = (this.state.Active - 1) * this.state.Page
      let perpage = this.state.Active * this.state.Page
      const P = this.state.dataA.slice(offset, perpage)
      this.setState({
        data: P,
      })
    }
  }

  componentDidMount = () => {
    Axios.post(REQUEST_URL + "/previewnewpage/", {}).then(response => {
      if (response.data.status) {
        let offset = (this.state.Active - 1) * this.state.Page
        let perpage = this.state.Active * this.state.Page
        const PageCount = Math.ceil(response.data.data.length / this.state.Page)
        for (var x = 1; x < PageCount + 1; x++) {
          this.state.PageCount.push(x)
        }
        const P = response.data.data.slice(offset, perpage)
        this.setState({
          dataA: response.data.data,
          data: P,
          Active: 1,
        })
      }
    })
  }

  Previous = () => () => {
    if (this.state.Active !== 1) {
      this.setState({
        Active: this.state.Active - 1,
      })
    }
  }

  Next = () => () => {
    if (this.state.Active < this.state.PageCount.length) {
      this.setState({
        Active: this.state.Active + 1,
      })
    }
  }

  Pagination = page => () => {
    this.setState({
      Active: page,
    })
  }

  render() {
    return (
      <div
        className=""
        style={{
          width: "100%",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        <Row>
          <Col md="12" xs="12">
            <label className="previewhead">
              ด้วยกระแส SANDALS รองเท้าแตะ เกิดความนิยมอยู่ตลอด ทำให้ช่วงนี้มองไปทางไหนก็มักจะเห็นวัยรุ่นทั้งหญิงและชาย
              นำรองเท้าแตะแมทช์กับเสื้อผ้าที่หลากหลาย ทำให้รองเท้าแตะเป็นหนึ่งเรื่องราวในด้านแฟชั่น
              และที่สำคัญเป็นรองเท้าเพื่อสุขภาพ อีกทั้ง มีการดีไซน์ที่ทันสมัย ตอบโจทย์คนรุ่นใหม่แน่นอ
              เราจะมาแนะนำให้เพื่อนๆ ได้รู้จักรองเท้าแตะแบรนด์ไทย COLLECTION ใหม่จาก ADDA
              ซึ่งวันนี้เราได้รวบรวมมาให้รู้จักทั้งหมด 5 รุ่น จะมีรุ่นไหนบ้างนั้น ลองมาดูกันเลย
            </label>
          </Col>
        </Row>
        {this.state.data.map((item, index) => {
          return (
            <div key={index}>
              {index % 2 === 0 ? (
                <Row style={{ marginTop: "2%" }}>
                  <Col md="7" xs="12">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          `/details/?${item.item_sku + "+" + item.item_id}`
                        )
                      }
                    >
                      <img
                        src={PATH_URL + "previewnew/" + item.nameimg}
                        width="100%"
                      />
                    </a>
                  </Col>
                  <Col md="1"></Col>
                  <Col
                    md="4"
                    xs="12"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <label className="previewfont">
                      <p
                        style={{
                          fontSize: "32px",
                          fontWeight: "bolder",
                          textDecoration: "underline",
                        }}
                      >
                        {item.hname}
                      </p>
                      {item.description}
                    </label>
                  </Col>
                </Row>
              ) : (
                <Row style={{ marginTop: "2%" }}>
                  <Col
                    md="4"
                    xs="12"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <label className="previewfont">
                      <p
                        style={{
                          fontSize: "32px",
                          fontWeight: "bolder",
                          textDecoration: "underline",
                        }}
                      >
                        {item.hname}
                      </p>
                      {item.description}
                    </label>
                  </Col>
                  <Col md="1"></Col>
                  <Col md="7" xs="12" style={{ cursor: "pointer" }}>
                    <a
                      onClick={() =>
                        window.open(
                          `/details/?${item.item_sku + "+" + item.item_id}`
                        )
                      }
                    >
                      <img
                        src={PATH_URL + "previewnew/" + item.nameimg}
                        width="100%"
                      />
                    </a>
                  </Col>
                </Row>
              )}
            </div>
          )
        })}

        <Row style={{ marginTop: "2%" }}>
          <Col md="7" xs="12">
            <label className="previewfont">
              นี่ก็คือ 5 รองเท้าแตะสวยๆ ซึ่งแต่ละคู่คุณภาพยอดเยี่ยม ใส่เดินเท่ๆ
              หวังว่าจะเป็นที่ชื่นชอบของเพื่อนๆ ทุกคน หากเพื่อนๆ
              สนใจคู่ไหนเป็นพิเศษก็รีบๆ จัดหามาให้กับตัวเองกันได้เลย
            </label>
            <label className="previewfont">
              ช้อปได้ที่ 
              <span
                style={{
                  color: "red",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://www.lazada.co.th/shop/adda-online-shop-official"
                  )
                }
              >
                LAZADA
              </span>
               หรือ 
              <span
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("https://shopee.co.th/adda_online_shop")
                }
              >
                SHOPEE
              </span>
            </label>
          </Col>
        </Row>
        <Row style={{ marginTop: "5%" }}>
          {this.state.PageCount.length > 1 ? (
            <Col md="12" xs="12" sm="12">
              <nav
                aria-label="..."
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ul className="pagination">
                  <li className="page-item" onClick={this.Previous()}>
                    <a
                      className="page-link"
                      style={{
                        cursor: "pointer",
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      Previous
                    </a>
                  </li>

                  {this.state.PageCount.map(item => {
                    return (
                      <li className="page-item" onClick={this.Pagination(item)}>
                        <a
                          className={
                            this.state.Active === item
                              ? "page-link activepage"
                              : "page-link"
                          }
                          style={{
                            cursor: "pointer",
                            color: "black",
                            fontSize: "18px",
                          }}
                        >
                          {item}
                        </a>
                      </li>
                    )
                  })}

                  <li className="page-item" onClick={this.Next()}>
                    <a
                      className="page-link"
                      style={{
                        cursor: "pointer",
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
    )
  }
}

export default PreviewNew
